import React, { useContext, useEffect, useState } from 'react';

import { Layout, Seo } from 'components';
import { appContext } from 'providers/appProvider';

const ImportantSafetySInformation = () => {
  const { appConfigs } = useContext(appContext);
  const siteIndications = appConfigs.siteOptions.INDICATIONS;
  return (
    <Layout indication={siteIndications.hcp} className="hcp-isi-page">
      <Seo indication={siteIndications.hcp} />
    </Layout>
  );
};

export default ImportantSafetySInformation;
